import React, { useState } from 'react';
import {IntlProvider} from 'react-intl';

// i18n
import message_en from 'src/language/en.json';
import message_th from 'src/language/th.json';
export const Context = React.createContext();
const local = navigator.language;
let lang;
if (local === 'th') {
   lang = message_th;
}else {
   lang = message_en;
}
const LanguageContext = (props) => {
    
   const [locale, setLocale] = useState(local);
   const [messages, setMessages] = useState(lang);
   function selectLanguage(e) {
       const newLocale = e.target.value;
       setLocale(newLocale);
       if (newLocale === 'en') {
            setMessages(message_en);
       } else {
            setMessages(message_th);
       }
   }
   return (
       <Context.Provider value = {{locale, selectLanguage}}>
           <IntlProvider messages={messages} locale={locale}>
               {props.children}
           </IntlProvider>
       </Context.Provider>
   );
}
export default LanguageContext;