import { lazy, Suspense } from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import LanguageContext from "./components/LanguageContext";
import './App.css';

const Layout = lazy(() => import("src/components/layout/Layout"));

const HomePage = lazy(() => import("src/containers/home/HomePage"));
const ServicePage = lazy(() => import("src/containers/service/ServicePage"));
const ProductListPage = lazy(() => import("src/containers/catalog/ProductListPage"));
const ProductDetailPage = lazy(() => import("src/containers/catalog/ProductDetailPage"));
const AboutPage = lazy(() => import("src/containers/about/AboutPage"));
const CustomerPage = lazy(() => import("src/containers/customer/CustomerPage"));
const ContactPage = lazy(() => import("src/containers/contact/ContactPage"));

function App() {

  return (
    <BrowserRouter>
      <LanguageContext>
        <Suspense fallback={<Layout />}>
          <Routes>
            <Route element={<Layout />}>
              <Route index element={<HomePage />} />
              <Route path="service" element={<ServicePage />} />
              <Route path="product" element={<ProductListPage />} />
              <Route path="product/detail/:id" element={<ProductDetailPage />} />
              <Route path="about-us" element={<AboutPage />} />
              <Route path="our-customer" element={<CustomerPage />} />
              <Route path="contact" element={<ContactPage />} />
            </Route>
          </Routes>
        </Suspense>
      </LanguageContext>
    </BrowserRouter>
  );
}

export default App;
